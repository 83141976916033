import { submitQuote, getAllQuotes, getQuote, updateQuote, getQuoteBatch } from "../../api/quote";
// import { useSnackbar } from 'notistack';
import { useMutation } from "react-query";

function useSubmitQuote() {
  // const { enqueueSnackbar } = useSnackbar();
  return useMutation(submitQuote, {
    // onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onError: (err) => console.log(err.message, { variant: "error" }),
    onSuccess: () =>
      console.log("Quote has been submited successfuly", { variant: "success" }),
    // onSuccess: () => enqueueSnackbar('شما با موفقیت وارد پنل شدید.', { variant: 'success' }),
  });
}

function useUpdateQuote() {
  // const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateQuote, {
    // onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
    onError: (err) => console.log(err.message, { variant: "error" }),
    onSuccess: () =>
      console.log("Quote has been updated successfuly", { variant: "success" }),
    // onSuccess: () => enqueueSnackbar('شما با موفقیت وارد پنل شدید.', { variant: 'success' }),
  });
}

function useGetAllQuotes() {
    // const { enqueueSnackbar } = useSnackbar();
    return useMutation(getAllQuotes, {
      // onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
      onError: (err) => console.log(err.message, { variant: "error" }),
      });
  }

  function useGetQuote() {
    // const { enqueueSnackbar } = useSnackbar();
    return useMutation(getQuote, {
      // onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
      onError: (err) => console.log(err.message, { variant: "error" }),
      });
  }
  

  function useGetQuoteBatch() {
    // const { enqueueSnackbar } = useSnackbar();
    return useMutation(getQuoteBatch, {
      // onError: (err) => enqueueSnackbar(err.message, { variant: 'error' }),
      onError: (err) => console.log(err.message, { variant: "error" }),
      });
  }
  

export { useSubmitQuote, useGetAllQuotes, useGetQuote, useUpdateQuote, useGetQuoteBatch };
