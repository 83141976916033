function potpack(boxes, fabricWidth, fabricHeight = Infinity, hspace = 1, vspace = 0, hfuse = 1, vfuse = 1) {
    // Calculate total box area
    let area = 0;
    boxes.forEach(box => {
        area += box.a;
    });

    // Sort boxes for optimal packing: larger area, then width, then height
    // boxes.sort((x, y) => y.a - x.a || y.w - x.w || y.h - x.h);
    boxes.sort((x, y) => y.a - x.a); // Sort by area first
    boxes.sort((x, y) => y.w - x.w); // Then by width
    boxes.sort((x, y) => y.h - x.h); // Finally by height


    // Initial space
    let spaces = [{ x: 0, y: 0, w: fabricWidth, h: fabricHeight }];
    let width = fabricWidth;
    let height = 0;

    // Packing logic
    boxes.forEach(box => {
        // Fuse spaces vertically if vfuse is enabled
        // Fuse spaces horizontally if hfuse is enabled
        if (hfuse) {
            spaces.sort((a, b) => a.y - b.y);
            for (let i = 0; i < spaces.length - 1; i++) {
                let space1 = spaces[i];
                let space2 = spaces[i + 1];
                if (space1.y === space2.y && space1.x + space1.w === space2.x) {
                    space1.w += space2.w;
                    spaces.splice(i + 1, 1);
                    i--;
                }
            }
        }
        if (vfuse) {
            spaces.sort((a, b) => a.x - b.x);
            for (let i = 0; i < spaces.length - 1; i++) {
                let space1 = spaces[i];
                let space2 = spaces[i + 1];
                if (space1.x === space2.x && space1.y + space1.h === space2.y) {
                    space1.h += space2.h;
                    spaces.splice(i + 1, 1);
                    i--;
                }
            }
        }

        

        // Sort spaces to prioritize packing closer to the origin
        spaces.sort((a, b) => a.y - b.y || a.x - b.x);

        // Find a space that fits the current box
        for (let i = 0; i < spaces.length; i++) {
            let space = spaces[i];

            if (box.w <= space.w && box.h <= space.h) {
                // Place the box in the space
                box.x = space.x;
                box.y = space.y;

                // Update the height for the used space
                height = Math.max(height, box.y + box.h);

                // Adjust or remove the used space
                if (box.w === space.w && box.h === space.h) {
                    spaces.splice(i, 1);
                } else if (box.w === space.w) {
                    space.y += box.h;
                    space.h -= box.h;
                } else if (box.h === space.h) {
                    space.x += box.w;
                    space.w -= box.w;
                } else {
                    spaces.push({
                        x: space.x + box.w,
                        y: space.y,
                        w: space.w - box.w,
                        h: box.h
                    });
                    space.y += box.h;
                    space.h -= box.h;
                }
                break;
            }
        }
    });

    return {
        w: width,
        h: height,
        fill: area
    };
}

export default potpack;