import React, { useState, useRef } from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';


import { tableCellClasses } from '@mui/material/TableCell';
import RoomForm from "../Components/RoomForm";
import { styled } from '@mui/material/styles';
import { Alert, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSubmitQuote } from '../hooks/quote';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewSharp'; // Import the icon


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#235169",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function toFraction(decimal) {
  const tolerance = 1.0E-6;
  let h1 = 1, h2 = 0, k1 = 0, k2 = 1;
  let b = decimal;
  do {
    const a = Math.floor(b);
    const aux = h1; h1 = a * h1 + h2; h2 = aux;
    const auxk = k1; k1 = a * k1 + k2; k2 = auxk;
    b = 1 / (b - a);
  } while (Math.abs(decimal - h1 / k1) > decimal * tolerance);

  return `${h1}/${k1}`;
}


const CurtainForm = () => {
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('')
  const [includeTax, setIncludeTax] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [installation, setInstallation] = useState(false);
  const [delivery, setDelivery] = useState(false)
  const priceTableRef = useRef();
  const materialTableRef = useRef();
  const [alertOpen, setAlertOpen] = useState(false);
  const navigate = useNavigate();

  const { mutate: submitQuoteForm, isLoading: isSubmitingQuoteForm } = useSubmitQuote();

  const [rooms, setRooms] = useState([{
    roomName: 'Living Room',
    curtainType: '',
    index:1,
    fabric: '',
    height: '',
    width: '',
    unit: 'inches',
    installation: false,
    price: 0,
    widthFraction: 0,
    heightFraction: 0
  }]);

  const curtainTypes = {
    Zebra: [
      { name: 'Aurora', price: 80},
      { name: 'Lumina', price: 90 },
      { name: 'Pearl', price: 95 },
    ],
    Shade: [
      {name: 'MC', price: 60},
      { name: 'PV', price: 70 },
      { name: 'NC', price: 80 },
    ],
  };

  const updateRoom = (index, updatedRoom) => {
    const updatedRooms = [...rooms];
    updatedRooms[index] = updatedRoom;
    setRooms(updatedRooms);
  };

  const addRoom = () => {
    setCalculated(false)
    setRooms([...rooms, {
      roomName: `Room ${rooms.length + 1}`,
      index:rooms.length+1,
      curtainType: '',
      fabric: '',
      height: '',
      width: '',
      unit: 'inches',
      motorized: false,
      price: 0,
      widthFraction: 0,
      heightFraction: 0
    }]);
  };

  const removeRoom = (index) => {
    const updatedRooms = rooms.filter((_, roomIndex) => roomIndex !== index);
    setRooms(updatedRooms);
  };

  const duplicateRoom = (index) => {
    let baseRoom = rooms[index]
    setCalculated(false)
    setRooms([...rooms, {
      roomName: baseRoom.roomName + `_copy`,
      curtainType: baseRoom.curtainType || "",
      fabric: baseRoom.fabric|| '',
      height: baseRoom.height|| '',
      width: baseRoom.width||'',
      unit: baseRoom.unit||'inches',
      motorized: baseRoom.motorized||false,
      price: 0,
      widthFraction: baseRoom.widthFraction|| 0,
      heightFraction: baseRoom.heightFraction || 0
    }]);
  };

  const calculatePriceForRoom = (room) => {
    const { unit, height, width, fabric, motorized, curtainType, widthFraction, heightFraction } = room;
    console.log(widthFraction, heightFraction)
    const heightInMeters = unit === 'cm' ? (parseFloat(height) + parseFloat(heightFraction)) / 100 : (parseFloat(height) + parseFloat(heightFraction)) * 0.0254;
    let widthInMeters = unit === 'cm' ? (parseFloat(width) + parseFloat(widthFraction)) / 100 : (parseFloat(width) + parseFloat(widthFraction)) * 0.0254;

    console.log(heightInMeters, widthInMeters)

    if (widthInMeters < 1) widthInMeters = 1;

    const area = heightInMeters * widthInMeters;
    const selectedFabric = curtainTypes[curtainType]?.find(f => f.name === fabric);
    const fabricPrice = selectedFabric ? selectedFabric.price : 0;

    let price = area * fabricPrice;
    let basePrice = price;
    if (motorized) price += 150;
    // if (delivery) price += 50;
    // if (installation) price += 30;

    return { price, basePrice };
  };

  const handleSubmit = () => {
    let invalidRooms = rooms.filter((room) => !room.width || !room.height || !room.fabric || !room.curtainType)
    if (invalidRooms.length > 0) {
      alert("Please fill all required fields(width, height, type, fabric) for each room.")
      return
    }
    const updatedRooms = rooms.map(room => ({
      ...room,
      ...calculatePriceForRoom(room)
    }));
    setRooms(updatedRooms);
    setCalculated(true);
  };


  const handleSubmitQuote = () => {
    let invalidRooms = rooms.filter((room) => !room.width || !room.height || !room.fabric || !room.curtainType)
    if (invalidRooms.length > 0) {
      alert("Please fill all required fields(width, height, type, fabric) for each room.")
      return
    }

    const updatedRooms = rooms.map(room => ({
      ...room,
      ...calculatePriceForRoom(room)
    }));
    setRooms(updatedRooms);
    setCalculated(true);

    submitQuoteForm({
      customerName,
      formJSON: rooms,
      email: customerEmail,
      includeTax,
      delivery,
      installation,
      customerPhoneNumber,
      overallPrice:(calculateTotalPrice() * (includeTax ? 1.13 : 1)).toFixed(2)
    }, {
      onSuccess: (response) => {
        setAlertOpen(true); // Show the success alert
        setTimeout(()=>navigate("/"),1000)
      }
    })
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false); // Close the alert when needed
  };

  const calculateTotalPrice = () => {
    let roomPrices = rooms.reduce((total, room) => total + parseFloat(room.price), 0);
    let deliveryPrice = delivery ? 50 : 0;
    let installationPrice = installation ? Math.max(50, rooms.length * 30) : 0;
    let totalPrice = roomPrices + deliveryPrice + installationPrice;
    return totalPrice.toFixed(2);
  };



  return (
    <Grid style={{ paddingBottom: "100px", paddingTop: "50px" }} container spacing={2}>

      <Grid item xs={12} style={{display:"flex"}}>
  <Button style={{marginRight:"10px", minWidth:"30px"}} color="primary" variant="contained" onClick={() => navigate(`/`)}>
      <ArrowBackIcon />
    </Button>
        <Typography variant="h5">Curtain Price Calculator</Typography>
      </Grid>

      {/* Customer Information */}
      <Grid item xs={12}>
        <TextField
          label="Name"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Email"
          value={customerEmail}
          onChange={(e) => setCustomerEmail(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="PhoneNumber"
          value={customerPhoneNumber}
          onChange={(e) => setCustomerPhoneNumber(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        {/* Room Forms */}
        {rooms.map((roomData, index) => (
          <RoomForm
            key={index}
            roomIndex={index}
            roomData={roomData}
            updateRoom={updateRoom}
            removeRoom={removeRoom}
            duplicateRoom={duplicateRoom}
          />
        ))}
      </Grid>

      {/* Add Room Button */}
      <Grid item xs={12}>
        <Button fullWidth variant="contained" sx={{ backgroundColor: "#235169" }} color="primary" onClick={addRoom} >
          <AddCircleOutline /> Add Room
        </Button>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={includeTax} onChange={(e) => setIncludeTax(e.target.checked)} />}
          label="Include Tax"
        />

        <FormControlLabel
          control={<Checkbox checked={delivery} onChange={(e) => {
            if (e.target.checked) setInstallation(false);
            setDelivery(e.target.checked)
          }} />}
          label="Delivery"
        />
        <FormControlLabel
          control={<Checkbox checked={installation} onChange={(e) => {
            if (e.target.checked) setDelivery(false);
            setInstallation(e.target.checked)
          }} />}
          label="Installation (Delivery Included)"
        />
      </Grid>

      {/* Calculate Button */}
      <Grid item xs={12}>
        <Button variant="contained" fullWidth sx={{ backgroundColor: "#235169" }} color="primary" onClick={handleSubmit}>
          Calculate Price
        </Button>
      </Grid>
      {calculated && <Grid item xs={12}>
        <Grid container>


          {/* Summary Table */}
          <Grid item xs={12}>
            <Typography variant="h6">Price Breakdown</Typography>
            <Table ref={priceTableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Room</StyledTableCell>
                  <StyledTableCell>Base Price</StyledTableCell>
                  <StyledTableCell>Motorized (+$150)</StyledTableCell>
                  <StyledTableCell>Total Price</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {rooms.map((room, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{room.roomName}</StyledTableCell>
                    <StyledTableCell>${room.basePrice.toFixed(2)}</StyledTableCell>
                    <StyledTableCell>{room.motorized ? "+$150" : "$0"}</StyledTableCell>
                    <StyledTableCell>${room.price.toFixed(2)}</StyledTableCell>
                  </StyledTableRow>
                ))}


                {delivery && <StyledTableRow>
                  <StyledTableCell colSpan={3}>Delivery</StyledTableCell>
                  <StyledTableCell>$50</StyledTableCell>
                </StyledTableRow>}

                {installation && <StyledTableRow>
                  <StyledTableCell colSpan={3}>Installation (Delivery Included)</StyledTableCell>
                  <StyledTableCell>${Math.max(50, rooms.length * 30)}</StyledTableCell>
                </StyledTableRow>}


                <StyledTableRow>
                  <StyledTableCell colSpan={3}>Sub-total Price</StyledTableCell>
                  <StyledTableCell>${calculateTotalPrice()}</StyledTableCell>
                </StyledTableRow>
                {includeTax && <StyledTableRow>
                  <StyledTableCell colSpan={3}>HST (13%)</StyledTableCell>
                  <StyledTableCell>${(calculateTotalPrice() * 0.13).toFixed(2)}</StyledTableCell>
                </StyledTableRow>}
                <StyledTableRow>
                  <StyledTableCell colSpan={3}>Total Price</StyledTableCell>
                  <StyledTableCell>${(calculateTotalPrice() * (includeTax ? 1.13 : 1)).toFixed(2)}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Grid>

          {/* Material Table */}
          {/* <Grid item xs={12}>
            <Typography variant="h6">Material Breakdown</Typography>
            <Table ref={materialTableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Room</StyledTableCell>
                  <StyledTableCell>Cassette Length</StyledTableCell>
                  <StyledTableCell>Tube Length</StyledTableCell>
                  <StyledTableCell>Fabric Width</StyledTableCell>
                  <StyledTableCell>Fabric Height</StyledTableCell>
                  <StyledTableCell>Bottom-Bar (Bar)</StyledTableCell>
                  <StyledTableCell>Bottom-Bar (Tube)</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {rooms.map((room, index) => {
                  const windowWidth = (parseFloat(room.width) + parseFloat(+room.widthFraction)) * (room.unit === "inches" ? 1 : 0.393701);
                  const windowHeight = (parseFloat(room.height) + parseFloat(room.heightFraction)) * (room.unit === "inches" ? 1 : 0.393701);
                  const cassetteLength = windowWidth - 1 / 8;
                  const tubeLength = windowWidth - 3 / 4;
                  const fabricWidth = windowWidth - 1;
                  const fabricHeight = windowHeight + 2.5;
                  const bottomBarLength = fabricWidth + 1 / 4;
                  const bottomBarTubeLength = room.curtainType === 'Zebra' ? fabricWidth - 1 / 8 : null;

                  const formatFractional = (value) => {
                    const wholePart = Math.floor(value);
                    const decimalPart = value - wholePart;
                    return decimalPart > 0 ? `${wholePart} ${toFraction(decimalPart)}` : `${wholePart}`;
                  };

                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{room.roomName}</StyledTableCell>
                      <StyledTableCell>{formatFractional(cassetteLength)} in</StyledTableCell>
                      <StyledTableCell>{formatFractional(tubeLength)} in</StyledTableCell>
                      <StyledTableCell>{formatFractional(fabricWidth)} in</StyledTableCell>
                      <StyledTableCell>{formatFractional(fabricHeight)} in</StyledTableCell>
                      <StyledTableCell>{formatFractional(bottomBarLength)} in</StyledTableCell>
                      <StyledTableCell>{bottomBarTubeLength ? `${formatFractional(bottomBarTubeLength)} in` : '-'}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid> */}
          <Grid item xs={12}>
            <Button variant="contained" fullWidth sx={{ backgroundColor: "#235169" }} color="primary" onClick={handleSubmitQuote}>
              Save Quote
            </Button>
          </Grid>

          {/* Print Button */}
          {/* <Grid item xs={12}>
            <Button variant="contained" sx={{ backgroundColor: "#235169" }} color="secondary" onClick={()=>handlePrint(priceTableRef)}>
              Print
            </Button>
          </Grid> */}
        </Grid>
      </Grid>}

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleAlertClose} severity="success" variant="filled">
          Quote submitted successfully!
        </Alert>
      </Snackbar>

    </Grid>
    
  );
};

export default CurtainForm;
