import axios from 'lib/axios';


const submitQuote = async (body) => {
  const { data } = await axios.post(`/quote`, body);
  return data;
};

const updateQuote = async ({body,quoteId}) => {
  console.log("updateQuote called:",body,quoteId)
  const { data } = await axios.put(`/quote/${quoteId}`, body);
  return data;
};

const getQuote = async (id) => {
  const { data } = await axios.get(`/quote/${id}`);
  return data;
};
const getQuoteBatch = async (ids) => {
  const { data } = await axios.get(`/quote/batch/${ids}`);
  return data;
};

const getAllQuotes = async () => {
  const { data } = await axios.get(`/quote`);
  return data;
};





export { submitQuote, getAllQuotes, getQuote, updateQuote, getQuoteBatch };
