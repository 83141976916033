import axios from "axios";

// api
export const HTTP_STATUS = {
  GONE: 410,
  Conflict: 409,
  Forbidden: 403,
  NotFound: 404,
  BadRequest: 400,
  NotAuthorized: 401,
  TooManyRequests: 429,
  ServerInternal: 500,
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

export default instance;
