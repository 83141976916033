import React, {
  useState,
  useRef,
  useEffect,
} from "react";
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";

import potpack from 'tools/potpack';

import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNewSharp"; // Import the icon

import { Alert, Snackbar } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { useGetQuoteBatch } from "../hooks/quote";


const CurtainFabricCuts = () => {
  const location = useLocation()
  const passedData = location.state;
  const [calculated, setCalculated] = useState(false);
  // const { quoteId } = useParams();
  const [alertOpen, setAlertOpen] = useState(false);



  //=========

  const [fabricWidth, setFabricWidth] = useState(110);
  const [fabricHeight, setFabricHeight] = useState(1000000);
  const [boxes, setBoxes] = useState({});
  const [info, setInfo] = useState({});

  const infoByFabric = useRef({});

  //=============

  const navigate = useNavigate();

  const { mutate: getQuoteBatch, isLoading } = useGetQuoteBatch();

  const canvasRefs = useRef({}); // Initialize canvasRefs as an empty object
  const fabricWidths = {
    "Lumina": 110,
    "Aurora": 110,
    "Pearl": 110,
    'MC': 110,
    'PV': 110,
    'NC': 110,
  }

  useEffect(() => {
    // Populate canvasRefs with refs for each fabric type in boxes
    Object.keys(boxes).forEach(fabricType => {
      if (!canvasRefs.current[fabricType]) {
        canvasRefs.current[fabricType] = React.createRef(); // Add ref if not present
      }
    });
     handleUpdate()
          setTimeout(()=>handleUpdate(),300);
  }, [boxes]); // Run this whenever the boxes object changes


  useEffect(() => {
    let quoteIds = passedData.selectedQuotes;
    if (!quoteIds.length) return;

    getQuoteBatch(quoteIds.join(","), {
      onSuccess: (response) => {
        if (response.status === "OK") {
          const quotes = response.quotes;
          let totalRooms = {}
          let fabrics = {}
          for (let quote of quotes) {
            for (let room of quote.formJSON) {
              let roomPrefix = room.curtainType === "Zebra" ? "zb" : "sb";
              let roomId = `${roomPrefix}-${quote.quoteId}-${room.index}`
              totalRooms[room.fabric] = [...(totalRooms[room.fabric] || []), { roomId, ...room }]
              fabrics[room.fabric] = [...(fabrics[room.fabric] || []), { height: room.height, width: room.width, count: 1, id: roomId }]
            }
          }
          setBoxes(fabrics)
        }
      }
    });
  }, [passedData]);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false); // Close the alert when needed
  };
  //========================
  const handleUpdate = () => {

    

    for (const [fabricType, fabricBoxes] of Object.entries(boxes)) {
      const validatedBoxes = [];

      for (const box of fabricBoxes) {
        const boxWidth = parseFloat(box.width);
        const boxHeight = parseFloat(box.height);
        const boxCount = parseInt(box.count);

        if (isNaN(boxWidth) || isNaN(boxHeight) || isNaN(boxCount)) {
          // setInfo('Please input valid dimensions and quantities for each fabric type.');
          return;
        }

        if (boxWidth < 1 || boxHeight < 1 || boxCount < 1 || boxCount > 100) {
          // setInfo('Dimensions and quantities must be positive integers.');
          return;
        }

        for (let i = 0; i < boxCount; i++) {
          validatedBoxes.push({ w: boxWidth, h: boxHeight, a: boxWidth * boxHeight, id: box.id });
        }
      }

      const { w, h, fill } = potpack(validatedBoxes, fabricWidths[fabricType], Infinity, 1, 1, 0, 1);

      infoByFabric.current[fabricType] = {
        width: w,
        height: h,
        fill: fill,
        validatedBoxes,
      };

      // Draw canvas for each fabric type
      drawCanvas(fabricType, w, h, validatedBoxes);

      const packedBoxes = validatedBoxes.filter(box => !isNaN(box.x));
      setInfo(prevInfo=>({...prevInfo,[fabricType]:`Packed ${packedBoxes.length} out of ${validatedBoxes.length} rectangle(s) for ${fabricType}, ` +
        `using ${Math.round((100 * fill) / (w * h))}% of a ${w}x${h} fabric.`})
      );
    }
  };

  const drawCanvas = (fabricType, width, height, boxes) => {
    const canvas = canvasRefs.current[fabricType]?.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const scale = canvas.width / width;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.scale(scale, scale);

    boxes.forEach(box => {
      if (!isNaN(box.x)) {
        ctx.fillStyle = `hsl(195, 100%, ${Math.random() * 50 + 80}%)`;
        ctx.fillRect(box.x, box.y, box.w, box.h);
        ctx.strokeRect(box.x, box.y, box.w, box.h);
        ctx.fillStyle = '#000';
        ctx.font = "4px serif";
        ctx.fillText(`${box.w}W x ${box.h}H`, box.x + box.w / 4, box.y + box.h / 4);
        ctx.font = "3px serif";
        ctx.fillText(`${box.id}`, box.x + box.w / 4, box.y + box.h / 4 + 10);
      }
    });

    ctx.scale(1 / scale, 1 / scale);
  };

  //===================================

  return isLoading ? (
    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <CircularProgress />:
    </Box>
  ) : (
    <Grid style={{ paddingBottom: "100px", paddingTop: "50px" }} container spacing={2}>
      <Grid item xs={12} style={{ display: "flex" }}>
        <Button
          style={{ marginRight: "10px", minWidth: "30px" }}
          color="primary"
          variant="contained"
          onClick={() => navigate(`/`)}
        >
          <ArrowBackIcon />
        </Button>
        <Typography variant="h5">Material Breakdown and Measures</Typography>
      </Grid>

      {calculated && (
        <Grid item xs={12}>
          <Typography variant="h6">Fabric Cuts</Typography>
        </Grid>
      )}

      <Snackbar
        open={alertOpen}
        autoHideDuration={3500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: "100%" }}>
          Progress Updated successfully!
        </Alert>
      </Snackbar>

      <Grid xs={12}>
        {/* <button onClick={()=>{
         
          // setFabricWidth(0)
        }}>Generate</button> */}
        

        {Object.keys(boxes).map(fabricType => (
          <>
          <p>{info[fabricType]}</p>
          <Box
            key={fabricType}

            style={{
              width: "100%",
              maxWidth: "100%",
              overflow: "auto",
              border: "1px solid #ccc",
              marginTop: "16px",
            }}
          >
            <Typography variant="h6">{fabricType}</Typography>
            <canvas ref={canvasRefs.current[fabricType]} width={fabricWidth * 5} height={infoByFabric.current[fabricType]?.height * 5} />
          </Box>
          </>
        ))}
      </Grid>
    </Grid>
  );
};

export default CurtainFabricCuts;
