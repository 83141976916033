import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Grid,
} from "@mui/material";

const QuoteTable = ({ quotes }) => {
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const navigate = useNavigate();

  const handleRowSelect = (quoteId) => {
    setSelectedQuotes((prevSelected) =>
      prevSelected.includes(quoteId)
        ? prevSelected.filter((id) => id !== quoteId)
        : [...prevSelected, quoteId]
    );
  };

  const handleDetailsClick = (quoteId) => {
    // Redirect to the quote details page
    navigate(`/show-quote/${quoteId}`);
  };

  const handleEditClick = (quoteId) => {
    // Redirect to the edit quote page
    navigate(`/edit-quote/${quoteId}`);
  };

  const handlePrintClick = (quoteId) => {
    // Redirect to the print quote page
    navigate(`/print-quote/${quoteId}`);
  };

  const handleMeasuresClick = (quoteId) => {
    // Redirect to the print quote page
    navigate(`/show-material/${quoteId}`);
  };

  const isSelected = (quoteId) => selectedQuotes.includes(quoteId);

  return (
    <TableContainer component={Paper}>
      {selectedQuotes.length>0 && <Button variant="contained" style={{ width: "100%" }} onClick={() => navigate("/fabric",{state:{selectedQuotes}})}>
        Get fabric cuts for selected orders
        </Button>}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell sx={{ textAlign: "center" }}>quoteId</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell sx={{ textAlign: "center" }}>Price</TableCell>
            <TableCell sx={{ textAlign: "center" }}>Delivery</TableCell>
            <TableCell sx={{ textAlign: "center" }}>Installation</TableCell>
            <TableCell sx={{ textAlign: "center" }}>Created At</TableCell>
            <TableCell sx={{ textAlign: "center" }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quotes.map((quote) => (
            <TableRow
              key={quote.quoteId}
              hover
              selected={isSelected(quote.quoteId)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isSelected(quote.quoteId)}
                  onChange={() => handleRowSelect(quote.quoteId)}
                  onClick={(event) => event.stopPropagation()} // Prevents triggering on row click
                />
              </TableCell>
              
              <TableCell sx={{ textAlign: "center" }} >{quote.quoteId}</TableCell>
              <TableCell>{quote.customerName}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {quote.overallPrice > 0 ? `$${quote.overallPrice}` : "N/A"}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {quote.delivery ? "Yes" : "No"}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {quote.installation ? "Yes" : "No"}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {new Date(quote.createdAt).toLocaleString("en-us", {year: "numeric", month: "2-digit", day: "2-digit", minute: "2-digit", hour: "2-digit"})}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleDetailsClick(quote._id)}
                    >
                      Details
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleEditClick(quote._id)}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handlePrintClick(quote._id)}
                    >
                      Print
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleMeasuresClick(quote._id)}
                    >
                      Sizes
                    </Button>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default QuoteTable;
