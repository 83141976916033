import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
} from "@mui/material";
import { IconButton } from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Add } from "@mui/icons-material";
import { useGetAllQuotes } from "../hooks/quote";
import QuoteTable from "Components/QuoteTable";
import { useNavigate } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#235169",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function toFraction(decimal) {
  const tolerance = 1.0e-6;
  let h1 = 1,
    h2 = 0,
    k1 = 0,
    k2 = 1;
  let b = decimal;
  do {
    const a = Math.floor(b);
    const aux = h1;
    h1 = a * h1 + h2;
    h2 = aux;
    const auxk = k1;
    k1 = a * k1 + k2;
    k2 = auxk;
    b = 1 / (b - a);
  } while (Math.abs(decimal - h1 / k1) > decimal * tolerance);

  return `${h1}/${k1}`;
}

const CurtainForm = () => {
  const { mutate: getAllQuotes, isLoading } = useGetAllQuotes();
  const navigate = useNavigate();

  const [quotes, setQuotes] = useState([]);
  useEffect(() => {
    getAllQuotes(
      {},
      {
        onSuccess: (response) => {
          if (response.status === "OK") {
            setQuotes(response.allQuotes);
          }
        },
      }
    );
  }, [getAllQuotes]);

  const handleQuoteClick = (quote) => {
    // Handle what happens when a row is clicked (e.g., open quote details)
    console.log("Quote clicked:", quote);
  };

  return (
    <Grid
      style={{ paddingBottom: "100px", paddingTop: "50px", paddingLeft:"10px"}}
      container
      spacing={2}
    >
      <div style={{ width: "100%" }}>
        <h1>Quotes List</h1>
        {isLoading ? (
          <Box
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress />:
          </Box>
        ) : (
          <>
          
            <Button variant="contained" style={{ width: "100%", marginBottom:"10px" }} onClick={() => navigate("/submit-quote")}>
              Add new
              </Button>
            <QuoteTable quotes={quotes} onQuoteClick={handleQuoteClick} />
            
          </>
        )}
      </div>

      {/* <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleAlertClose} severity="success" variant="filled">
          Quote submitted successfully!
        </Alert>
      </Snackbar> */}
    </Grid>
  );
};

export default CurtainForm;
