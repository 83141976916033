import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  TextField,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNewSharp"; // Import the icon

import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useGetQuote } from "../hooks/quote";

import { useUpdateQuote } from "../hooks/quote";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#235169",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function toFraction(decimal) {
  const tolerance = 1.0e-6;
  let h1 = 1,
    h2 = 0,
    k1 = 0,
    k2 = 1;
  let b = decimal;
  do {
    const a = Math.floor(b);
    const aux = h1;
    h1 = a * h1 + h2;
    h2 = aux;
    const auxk = k1;
    k1 = a * k1 + k2;
    k2 = auxk;
    b = 1 / (b - a);
  } while (Math.abs(decimal - h1 / k1) > decimal * tolerance);
  if (h1 === 0) return ``;
  return `${h1}/${k1}`;
}

const CurtainForm = () => {
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [calculated, setCalculated] = useState(false);
  const materialTableRef = useRef();
  const { quoteId } = useParams();
  const [alertOpen, setAlertOpen] = useState(false);

  const navigate = useNavigate();

  const laborCost = 20;

  const priceMap = {
    cassete: 1.31,
    tube: 1.26,
    clutch:3.28,
    bottomBar: 0.84,
    bottomBarTube: 0.4,
    fabric: 7, // for each squared meter
    chain: 1,
    bottomBarCap:0.1,
    motor: 70
  }

  const { mutate: updateQuote, isLoading: isUpdatingQuoteForm } =
    useUpdateQuote();

  const { mutate: getQuote, isLoading } = useGetQuote();
  let totalCosts = 0;
  let totalCasseteLength = 0;
  let totalTubeLength = 0;
  let totalBottomBarLength = 0;
  let totalBottomBarTubeLength = 0;


  const rooms = useRef([
    {
      roomName: "Living Room",
      curtainType: "",
      fabric: "",
      height: "",
      width: "",
      unit: "inches",
      installation: false,
      price: 0,
      widthFraction: 0,
      heightFraction: 0,
    },
  ]);

  const [progress, setProgress] = useState(
    rooms.current.map(() => ({
      cassette: false,
      tube: false,
      fabric: false,
      bottomBar: false,
      bottomBarTube: false,
    }))
  );

  // Handler to toggle the progress state for each part
  const handleProgressChange = (index, part) => {
    console.log(index, part);
    console.log("progress: ", progress);
    let newProgress = progress.map((item, i) =>
      i === index ? { ...item, [part]: !item[part] } : item
    );
    setProgress((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, [part]: !item[part] } : item
      )
    );
    updateQuote({
      quoteId,
      body: {
        progress: newProgress,
      },
    },{
      onSuccess: (response) => {
        if (response.status === "OK") {
          console.log("Quote updated successfully");
        }
      },
      onError: (error) => {
        console.log(error);
      }
    });

  };



  useEffect(() => {
    if (!quoteId) return;
    // console.log("saaalaaa, ", quoteId);
    getQuote(quoteId, {
      onSuccess: (response) => {
        if (response.status === "OK") {
          const quote = response.quote;
          console.log("Quote: ", quote);
          rooms.current = quote.formJSON;
          
          setProgress(quote.progress || rooms.current.map(() => ({
            cassette: false,
            tube: false,
            fabricWidth: false,
            fabricHeight: false,
            bottomBar: false,
            bottomBarTube: false,
          })))


          // setRooms(quote.formJSON);
          setCustomerEmail(quote.email);
          setCustomerName(quote.customerName);
          setCustomerPhoneNumber(quote.customerPhoneNumber);
          setCalculated(true);
          // setTimeout(() => handleSubmit(), 1500);
        }
      },
    });
  }, [quoteId]);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false); // Close the alert when needed
  };
  const roundToEighth = (value) => {
    return Math.round(value * 8) / 8;
  };
  const formatFractional = (value) => {
    const wholePart = Math.floor(value);
    const decimalPart = value - wholePart;
    return decimalPart > 0
      ? `${wholePart} ${toFraction(decimalPart)}`
      : `${wholePart}`;
  };

  return isLoading ? (
    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <CircularProgress />:
    </Box>
  ) : (
    <Grid
      style={{ paddingBottom: "100px", paddingTop: "50px" }}
      container
      spacing={2}
    >
      {/* Customer Information */}
      <Grid item xs={12} style={{ display: "flex" }}>
        <Button
          style={{ marginRight: "10px", minWidth: "30px" }}
          color="primary"
          variant="contained"
          onClick={() => navigate(`/`)}
        >
          <ArrowBackIcon />
        </Button>
        <Typography variant="h5">Material Breakdown and Measures</Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Customer Name"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Customer Email"
          value={customerEmail}
          onChange={(e) => setCustomerEmail(e.target.value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="PhoneNumber"
          value={customerPhoneNumber}
          onChange={(e) => setCustomerPhoneNumber(e.target.value)}
          fullWidth
        />
      </Grid>

      {calculated && (
        <Grid item xs={12}>
          <Typography variant="h6">Material Breakdown</Typography>
          <Table ref={materialTableRef}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Room</StyledTableCell>
                <StyledTableCell>Cassette Length</StyledTableCell>
                <StyledTableCell>Tube Length</StyledTableCell>
                <StyledTableCell>Fabric Width</StyledTableCell>
                <StyledTableCell>Fabric Height</StyledTableCell>
                <StyledTableCell>Bottom-Bar (Bar)</StyledTableCell>
                <StyledTableCell>Bottom-Bar (Tube)</StyledTableCell>
                <StyledTableCell>Total Cost</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rooms.current.map((room, index) => {
                const windowWidth =roundToEighth(
                  (parseFloat(room.width) + parseFloat(room.widthFraction)) *
                  (room.unit === "inches" ? 1 : 0.393701));
                const windowHeight =roundToEighth(
                  (parseFloat(room.height) + parseFloat(room.heightFraction)) *
                  (room.unit === "inches" ? 1 : 0.393701));

                // Round dimensions to nearest 1/8th of an inch
                const cassetteLength = roundToEighth(windowWidth - 1 / 8);
                const tubeLength = roundToEighth(windowWidth - 3 / 4);
                const fabricWidth = roundToEighth(windowWidth - 1);
                const fabricHeight = roundToEighth(windowHeight + 2.5);
                const bottomBarLength = roundToEighth(fabricWidth + 1 / 4);
                const bottomBarTubeLength = 
                  room.curtainType === "Zebra"
                    ? roundToEighth(fabricWidth - 1 / 8)
                    : null;
                    
                    const fabricArea = ((room.curtainType === "Zebra"?2:1) * (fabricHeight *2.54 * fabricWidth * 2.54)) /10000 // area in squared meters
                    const rowPrice = cassetteLength/12 * priceMap["cassete"] + 
                          tubeLength/12 * priceMap["tube"] + 
                          fabricArea * priceMap["fabric"]+
                          bottomBarLength/12 * priceMap["bottomBar"]+
                          bottomBarTubeLength/12 * priceMap["bottomBarTube"]+
                          priceMap["bottomBarCap"] +
                          priceMap["chain"] +
                          priceMap["clutch"] +
                          (room.motorized?1:0) * priceMap["motor"]+
                          laborCost // labor cost;
                    totalCosts+=rowPrice;
                    totalCasseteLength+= cassetteLength/12;
                    totalBottomBarLength+=bottomBarLength/12;
                    totalBottomBarTubeLength+= bottomBarTubeLength/12;
                    totalTubeLength+= tubeLength/12;
                return (
                  <TableRow key={index}>
                    <TableCell>{room.index}</TableCell>
                    <TableCell>{room.roomName}</TableCell>
                    <TableCell>
                      <div
                        onClick={() => handleProgressChange(index, "cassette")}
                        style={{
                          width: "100px",
                          height: "20px",
                          backgroundColor: progress[index]?.cassette
                            ? "green"
                            : "gray",
                          cursor: "pointer",
                          textAlign: "center",
                          lineHeight: "20px",
                          color: "white",
                        }}
                      >
                        {formatFractional(cassetteLength)} in
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        onClick={() => handleProgressChange(index, "tube")}
                        style={{
                          width: "100px",
                          height: "20px",
                          backgroundColor: progress[index]?.tube
                            ? "green"
                            : "gray",
                          cursor: "pointer",
                          textAlign: "center",
                          lineHeight: "20px",
                          color: "white",
                        }}
                      >
                        {formatFractional(tubeLength)} in
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        onClick={() => handleProgressChange(index, "fabricWidth")}
                        style={{
                          width: "100px",
                          height: "20px",
                          backgroundColor: progress[index]?.fabricWidth
                            ? "green"
                            : "gray",
                          cursor: "pointer",
                          textAlign: "center",
                          lineHeight: "20px",
                          color: "white",
                        }}
                      >
                        {formatFractional(fabricWidth)} in
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        onClick={() => handleProgressChange(index, "fabricHeight")}
                        style={{
                          width: "100px",
                          height: "20px",
                          backgroundColor: progress[index]?.fabricHeight
                            ? "green"
                            : "gray",
                          cursor: "pointer",
                          textAlign: "center",
                          lineHeight: "20px",
                          color: "white",
                        }}
                      >
                        {formatFractional(fabricHeight)} in
                      </div>
                    </TableCell>
                    <TableCell>
                    <div
                        onClick={() => handleProgressChange(index, "bottomBar")}
                        style={{
                          width: "100px",
                          height: "20px",
                          backgroundColor: progress[index]?.bottomBar
                            ? "green"
                            : "gray",
                          cursor: "pointer",
                          textAlign: "center",
                          lineHeight: "20px",
                          color: "white",
                        }}
                      >

                      {formatFractional(bottomBarLength)} in
                      </div>
                      </TableCell>

                      <TableCell>
                    <div
                        onClick={() => handleProgressChange(index, "bottomBarTube")}
                        style={{
                          width: "100px",
                          height: "20px",
                          backgroundColor: progress[index]?.bottomBarTube
                            ? "green"
                            : "gray",
                          cursor: "pointer",
                          textAlign: "center",
                          lineHeight: "20px",
                          color: "white",
                        }}
                      >

                      {formatFractional(bottomBarTubeLength)} in
                      </div>
                      </TableCell>


                      <TableCell>
                    
                      {/* {formatFractional(bottomBarTubeLength)} in */}
                      ${rowPrice.toFixed(2)}
                    
                      </TableCell>

                    
                  </TableRow>
                  
                );
              })}
              <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>{Math.ceil(totalCasseteLength)} ft</TableCell>
                  <TableCell colSpan={3}>{Math.ceil(totalTubeLength)} ft</TableCell>
                  <TableCell>{Math.ceil(totalBottomBarLength)} ft</TableCell>
                  <TableCell>{Math.ceil(totalBottomBarTubeLength)} ft</TableCell>
                  <TableCell>${totalCosts.toFixed(2)}</TableCell>
                  
                  
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      )}

      {/* Success Alert */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={3500}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Progress Updated successfully!
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default CurtainForm;
